import { configureStore } from '@reduxjs/toolkit';
import {
  persistReducer, persistStore, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'

// Define action types as constants
const SET = 'set';
const SET_USER_DATA = 'setUserData';
const SET_REGISTER_DATA = 'setRegisterData';
const SET_CODE_HASH = 'setCodeHash';
const SET_PRODUCTS_AVAILABLE = 'setProductsAvailable';
const SET_TRANSACTIONS_DATA = 'setTransactionsData'
const SET_RECHARGES_DATA = 'setRechargesData'
const SET_MULTIPLE_NUMBERS = 'setMultipleNumbers'

const UPDATE_USER_WALLET = 'updateUserWallet';
const UPDATE_USER_TYPE = 'updateUserType';
const UPDATE_USER_IMAGE = 'updateUserImage';
const UPDATE_USER_DATA = 'updateUserData';
const UPDATE_TRANSACTIONS_DATA = 'updateTransactionsData';
const UPDATE_RECHARGES_DATA = 'updateRechargesData';

const LOGOUT = 'logout';

const initialState = {
  sidebarShow: true,
  userData: null,
  registerData: null,
  transactionsData: null,
  rechargesData: null,
  multipleNumbers: [],
  codeHash: '',
  productsAvailable: null,
}

const rootReducer = (state = initialState, { type, payload, ...rest }) => {
  switch (type) {
    case SET:
      return { ...state, ...rest };
    case SET_USER_DATA:
      return { ...state, userData: rest.userData };
    case SET_REGISTER_DATA:
      return { ...state, registerData: rest.registerData };
    case SET_CODE_HASH:
      return { ...state, codeHash: rest.codeHash };
    case SET_PRODUCTS_AVAILABLE:
      return { ...state, productsAvailable: rest.productsAvailable };
    case SET_TRANSACTIONS_DATA:
      return { ...state, transactionsData: rest.transactionsData };
    case SET_RECHARGES_DATA:
      return { ...state, rechargesData: rest.rechargesData };
      case SET_MULTIPLE_NUMBERS:
        return { ...state, multipleNumbers: rest.multipleNumbers };
    case UPDATE_USER_WALLET:
      return {
        ...state,
        userData: {
          ...state.userData,
          wallet: payload,
        },
      };
    case UPDATE_USER_TYPE:
      return {
        ...state,
        userData: {
          ...state.userData,
          type: payload,
        },
      };
    case UPDATE_USER_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          name: payload.first_name,
          last_name: payload.last_name,
          phone_number: payload.phone_number,
          company_name: payload.company_name,
          trading_address: payload.trading_address,
        },
      };
    case UPDATE_TRANSACTIONS_DATA:
      return {
        ...state,
        transactionsData: [payload,...state.transactionsData],
      };
      case UPDATE_RECHARGES_DATA:
        return {
          ...state,
          rechargesData: [payload,...state.rechargesData],
        };
    case UPDATE_USER_IMAGE:
      return {
        ...state,
        userData: {
          ...state.userData,
          profile_picture: payload,
        },
      };
    case LOGOUT:
      return { ...initialState, sidebarShow: state.sidebarShow }; // Reset to initial state  
    default:
      return state;
  }
};
const persistConfig = {
  key: 'root',
  storage,
  transforms: [],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistor = persistStore(store);

export { persistor, store };
