import React, { Suspense, useEffect } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import '../scss/style.scss';

// Containers
const DefaultLayout = React.lazy(() => import('../components/layout/DefaultLayout'));

// Pages
const LandingScreen = React.lazy(() => import('../components/views/landing/LandingScreen'));


const Login = React.lazy(() => import('../components/views/authentication/login/Login'));

const RegistrationForm = React.lazy(() => import('../components/views/authentication/register/RegistrationForm'));
const SendCode = React.lazy(() => import('../components/views/authentication/register/SendCode'));
const VerifyCode = React.lazy(() => import('../components/views/authentication/register/VerifyCode'));
const TermsAndConditions = React.lazy(() => import('../components/views/authentication/register/TermsAndConditions'));
const Register = React.lazy(() => import('../components/views/authentication/register/Register'));

const ForgotPassword = React.lazy(() => import('../components/views/authentication/password/ForgotPassword'));
const ChangePassword = React.lazy(() => import('../components/views/authentication/password/ChangePassword'));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const savedToken = localStorage.getItem('topup-token');
    if (savedToken) {
      const apiUrl = 'https://be.pcgtopup.net/api/v1/profile';

      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${savedToken}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          dispatch({ type: 'setUserData', userData: data ? data : {} });
        })
        .catch(error => {
          console.error('Fetch error:', error);
        });
    } else {
      console.log('No access Token')
    }
  }, [dispatch]);

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/" name="Landing Page" element={<LandingScreen />} />

          <Route exact path="/login" name="Login Page" element={<Login />} />

          <Route exact path="/registration-form" name="Register Page" element={<RegistrationForm />} />
          <Route exact path="/send-code" name="Send Code Page" element={<SendCode />} />
          <Route exact path="/verify-code" name="Verify Code Page" element={<VerifyCode />} />
          <Route exact path="/terms-conditions" name="Terms And Conditions Page" element={<TermsAndConditions />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />

          <Route exact path="/forgot-password" name="Forgot Password Page" element={<ForgotPassword />} />
          <Route exact path="/change-password" name="Change Password Page" element={<ChangePassword />} />

          <Route path="*" name={"Home"} element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
};

export default App;
